<template>
  <v-tab :ripple="false" :disabled="!enabled" >
    <v-layout shrink justify-start @click.prevent="checkIfBlocked" :class="active && 'zg-pc-line'">
      <v-layout column class="text-center zg-tab">
        <v-layout align-center>
          <div
            :style="{ color: active ? 'primary' : 'secondary' }"
            :class="['text-caption zg-tab-text', active && 'active']"
          >
            {{ value }}
          </div>
          <v-badge
            v-if="badge && !active"
            inline
            :content="badge.content"
            :color="badge.color"
          ></v-badge>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-tab>
</template>

<script>
export default {
  props: {
    value: {},
    badge: {},
    active: {
      type: Boolean,
      default: false,
    },
    blockCondition: {
      type: Function,
      default: () => false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    checkIfBlocked(event) {
      if (this.blockCondition()) {
        event.stopPropagation();
        this.$emit("blocked", this.value);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.zg-tab {
  height: 47px;
}
.zg-tab-text {
  font-weight: 500;

  &.active {
    font-weight: 600;
  }
}

.zg-pc-line {
  border-bottom: 3px solid black;
}
</style>